import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authn/ssh',
            name: 'profile-settings-authn-ssh',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-ssh" */ '../views/profile/authn/SSH.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/c2',
            name: 'service-admin-c2',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        },
        {
            path: '/service/dns',
            name: 'service-admin-dns',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        },
        {
            path: '/service/letsencrypt',
            name: 'service-admin-letsencrypt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-letsencrypt" */ '../views/service/Letsencrypt.vue'),
        },
        {
            path: '/service/tls',
            name: 'service-admin-tls',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "service-admin-tls" */ '../views/service/TLS.vue'),
        },
        {
            path: '/user/:userId/check/ssh',
            name: 'user-check-ssh',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-check-ssh" */ '../views/user/check/SSH.vue'),
        },
        {
            path: '/user/:userId/search/volume',
            name: 'user-search-volume',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-search-volume" */ '../views/user/UserSearchVolume.vue'),
        },
        {
            path: '/user/account-list',
            name: 'user-account-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-account-list" */ '../views/user/AccountList.vue'),
        },
        {
            path: '/user/create/account',
            name: 'user-create-account',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-account" */ '../views/user/CreateAccount.vue'),
        },
        {
            path: '/account/:accountId',
            name: 'account-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-dashboard" */ '../views/account/Dashboard.vue'),
        },
        {
            path: '/account/:accountId/delete',
            name: 'account-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete" */ '../views/account/Delete.vue'),
        },
        {
            path: '/account/:accountId/create/domain',
            name: 'account-create-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-domain" */ '../views/account/CreateDomain.vue'),
        },
        {
            path: '/account/:accountId/create/volume',
            name: 'account-create-volume',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-volume" */ '../views/account/CreateVolume.vue'),
        },
        {
            path: '/account/:accountId/create/website',
            name: 'account-create-website',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-website" */ '../views/account/CreateWebsite.vue'),
        },
        {
            path: '/account/:accountId/create/website-alias',
            name: 'account-create-website-alias',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-create-website-alias" */ '../views/account/CreateWebsiteAlias.vue'),
        },
        {
            path: '/account/:accountId/search/domain',
            name: 'account-search-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-domain" */ '../views/account/SearchDomain.vue'),
        },
        // {
        //     path: '/account/:accountId/search/host',
        //     name: 'account-search-host',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "account-search-host" */ '../views/account/SearchHost.vue'),
        // },
        {
            path: '/account/:accountId/search/user',
            name: 'account-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-user" */ '../views/account/SearchUser.vue'),
        },
        {
            path: '/account/:accountId/search/volume',
            name: 'account-search-volume',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-volume" */ '../views/account/SearchVolume.vue'),
        },
        {
            path: '/account/:accountId/search/website',
            name: 'account-search-website',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-search-website" */ '../views/account/SearchWebsite.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain',
            name: 'account-view-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-domain" */ '../views/account/ViewDomain.vue'),
        },
        {
            path: '/account/:accountId/user/:userId',
            name: 'account-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-user" */ '../views/account/ViewUser.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/nameservers',
            name: 'account-edit-domain-nameservers',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-domain-nameservers" */ '../views/account/EditDomainNameservers.vue'),
        },
        {
            path: '/account/:accountId/verify/domain/:domain',
            name: 'account-verify-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-verify-domain" */ '../views/account/VerifyDomain.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/website-list',
            name: 'account-view-domain-website-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-view-domain-website-list" */ '../views/account/ViewDomainWebsiteList.vue'),
        },
        {
            path: '/account/:accountId/volume/:volumeId',
            name: 'account-edit-volume',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-volume" */ '../views/account/EditVolume.vue'),
        },
        {
            path: '/account/:accountId/volume/:volumeId/access',
            name: 'account-edit-volume-access',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-volume-access" */ '../views/account/EditVolumeAccess.vue'),
        },
        {
            path: '/account/:accountId/volume/:volumeId/delete',
            name: 'account-delete-volume',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-volume" */ '../views/account/DeleteVolume.vue'),
        },
        {
            path: '/account/:accountId/website/:websiteId',
            name: 'account-edit-website',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-website" */ '../views/account/EditWebsite.vue'),
        },
        {
            path: '/account/:accountId/website/:websiteId/check-certificate',
            name: 'account-check-website-certificate',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-check-website-certificate" */ '../views/account/CheckWebsiteCertificate.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/delete',
            name: 'account-delete-domain',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-delete-domain" */ '../views/account/DeleteDomain.vue'),
        },
        {
            path: '/account/:accountId/domain/:domain/dns',
            name: 'account-edit-dns',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "account-edit-dns" */ '../views/account/EditDns.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
